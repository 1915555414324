@import "core";
@import '@wearetla/tla-essentials-tools/partials/btn/btn-mixins';

.btn {
	@include btn();
	@include font-bold;

	font-size: 1.4rem;
	height: 4rem;
	min-width: 4rem;
	line-height: 1;
	padding: 0 2rem;
	border-radius: $radius-small;
	box-shadow: $shadow-input;

	@include media {
		padding: 0 1.5rem;
	}

	/*
	$bgColor,
	$textColor: $color-white,
	$hollowColor: false,
	$hollowHoverBg: false,
	$hoverColor: false,
	$borderColor: false,
	$hoverBg: false,
	*/
	@include btnColor($color-gray-light, $color-text, $color-text);

	// States
	&:disabled {
		opacity: .6;
		cursor: inherit;
	}

	// Shapes
	&.icon-only {
		padding-left: 0;
		padding-right: 0;
	}

	&.rounded {
		border-radius: 2rem;
	}

	&.big {
		height: 5rem;
		min-width: 5rem;
	}

	&.medium {
		height: 4rem;
		min-width: 4rem;
	}

	&.small {
		height: 3rem;
		min-width: 3rem;
	}

	&.text-big {
		font-size: 1.6rem;
	}

	&.text-small {
		font-size: 1.2rem;
	}

	&.block {
		display: flex;
		width: 100%;
	}

	&.nude {
		padding: 0;
		border: none;
		background-color: transparent;
		box-shadow: none;
		height: auto;
	}

	// Colors

	&.primary {
		color: $color-white;
		@include btnColor($color-primary, $color-white, $color-primary);
	}

	&.red {
		color: $color-white;
		@include btnColor($color-red, $color-white, $color-red);
	}

	&.white {
		color: $color-white;
		@include btnColor($color-white, $color-primary, $color-white);
	}

	&.text {
		color: $color-white;
		@include btnColor($color-text, $color-white, $color-text);
	}

	// Internal Parts
	.btn-icon {
		&.pre {
			margin-right: 1rem;
		}
		&.post {
			margin-left: 1rem;
		}
	}
}