@import "core";
.modal-container.modal-wallet-qr {	
	.modal-contentwrap {
		width: 98rem;
		display: flex;
	}

	.qr-content {
		padding: calc(9rem + var(--safe-area-insets-top, env(safe-area-inset-top))) 2rem calc(8.7rem + var(--safe-area-insets-bottom, env(safe-area-inset-bottom)));
	}

	.qr-title {
		@include font-bold;
		font-size: 3.2rem;
	}

	.qr-description {
		margin-top: 2rem;
		font-size: 1.2rem;
	}

		.description-icon {
			background-color: $color-gray;
			color: $color-white;
			border-radius: 50%;
			width: 1.5rem;
			height: 1.5rem;
			margin-right: .7rem;
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}

	.qr-codewrap {
		margin: 3rem 3rem 0;
		position: relative;
		border: .1rem solid $color-border;
		border-radius: $radius-general;
		padding-bottom: calc(100% - 6rem);
		transition: opacity $transition-hover ease;

		&.ended {
			opacity: .5;
		}
	}

		.codewrap-qr {
			position: absolute;
			width: calc(100% - 6rem);
			height: auto;
			left: 3rem;
			top: 3rem;
		}

	.qr-timer {
		text-align: center;
		margin-top: 2rem;
		line-height: 2rem;
		height: 2rem;
	}

	.qr-controls {
		margin: 1.8rem 0 0;
		text-align: center;
	}

		.controls-redirectbtn {
			margin-left: 2rem;
		}

		.controls-renewbtn {
			display: inline-flex;
			width: 10rem;
		}

	.qr-success {
		display: block;
		padding: 2rem;
		background-color: $color-bg;
		border-radius: $radius-general;
		text-align: center;
		margin-top: 2rem;
	}

		.success-title {
			display: block;
			font-size: 2rem;
		}

		.success-closebtn {
			margin-top: 2rem;
		}
}