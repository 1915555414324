// Base Colors
$color-black: #000;
$color-text: #313131;
$color-text-light: #737373;
$color-gray: #9D9D9D;
// $color-gray-light: #eceef1; //#9AB0C74F;

$color-red-light: #FF0028;
$color-red-medium: #D60023;
$color-red: #9A0113;
$color-red-dark: #AC0A28;
$color-red-darker: #5A0009;

$color-yellow-light: #FFECAC;

// $color-orange-dark: #FF4E21;
// $color-orange: #FF9000;
// $color-orange-light: #FFCC34;
// $color-orange-lighter: #FAE68C;
// $color-blue: #0060FF;

// Selection Colors
$color-primary-light: $color-red-light;
$color-primary: $color-red-dark;
$color-primary-dark: $color-red;

$color-bg: #f5f5f5;
$color-bg-light: #fafafa;

// Functional Colors
$color-border: #dbdbdb;
$color-border-light: $color-bg;
$color-autofill: $color-text;
$color-placeholder: $color-text-light;

// Status Colors
// $color-success: #59CC00;
// $color-success-bg: lighten($color-success, 50%);
$color-warning: #FF8900;
// $color-warning-bg: lighten($color-warning, 50%);

// Rediusses
$radius-small: .4rem;
$radius-general: .8rem;
$radius-big: 2.6rem;

// Misc
$slider-inline-arrow-width: 1083;
$event-image-ratio: 134.803921568627451%;

// Box Shadow
$shadow-general: #{"0rem .3rem 1.5rem 0rem rgba(0, 0, 0, 0.12)"}!default;